import store from '@/store'
import { cloneDeep, throttle, compact } from 'lodash'

export default {
  data () {
    return {
      loading: false,
      tableData: [],
      queryInfo: {
        pageNum: 1,
        pageSize: 15,
        keyword: null
      },
      total: 0,
      initQuery: {},
      visibleDialog: false // 默认弹窗显示控制
    }
  },
  mounted () {
    this.setInitQuery()
  },
  methods: {
    // 记录初始数据,用于重置搜索条的操作
    setInitQuery () {
      this.initQuery = cloneDeep(this.queryInfo)
    },
    // 清空 tableData
    clearTable () {
      while (this.tableData.length) this.tableData.pop()
    },
    /* 根据queryInfo 获取tableData
    * pageNum 页码,搜索/重置时需要
    * apiMethod 接口
     */
    async getTableData (apiMethod, pageNum) {
      this.loading = true
      if (pageNum) this.queryInfo.pageNum = pageNum
      this.clearTable()
      try {
        const res = await apiMethod(this.queryInfo)
        this.tableData = res.data.list
        this.total = res.data.total
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    // 获取学籍状态 list
    async getStuStatus () {
      if (!this.$store.state.systemParam.stuStatus.length) {
        await store.dispatch('systemParam/getSystemDictionary', 'stuStatus')
      }
      this.stuStatusList = this.$store.state.systemParam.stuStatus
    },
    // 清空数组
    clearList (arr) {
      while (arr.length) arr.pop()
    },
    /*
    * 显示弹窗
    * visibleDialog {boolean} 控制弹窗显示/隐藏
    * id {string} 一般为row的id,编辑状态要传
    * preTitle {string} 用于弹窗标题
    * title {string} 用于弹窗标题后缀
     */
    showDialog(id = '', title) {
      if (!title) title = ''
      this.visibleDialog = true
      this.$nextTick(() => {
        this.$refs.dialogRef.preTitle = id ? '编辑' : '新增'
        this.$refs.dialogRef.id = id
        this.$refs.dialogRef.title = title
        // 深拷贝原始formData
        this.$refs.dialogRef.setInitData()
      })
    },
    /* 自定义弹窗refs, 用于控制页面内第二个弹窗
    *  visible {boolean} 控制弹窗显示/隐藏
    *  Ref {string} 弹窗的ref值
    *  id {string} 一般为row的id,编辑状态要传
    *  preTitle {string} 用于弹窗标题
    *  title {string} 用于弹窗标题后缀
    */
    showSubDialog (visible, Ref, id = '', title = '') {
      this[visible] = true
      this.$nextTick(() => {
        this.$refs[Ref].id = id
        this.$refs[Ref].title = title
        // 保存原始formData
        this.$refs[Ref].setInitData()
      })
    },
    // 清空字段时，设为null
    handleClear (query, key) {
      query[key] = null
    },
    // 重置搜索条
    async clear () {
      const _pageSize = this.queryInfo.pageSize
      Object.keys(this.queryInfo).map(key => {
        if (key === 'pageSize') {
          this.queryInfo[key] = _pageSize
        } else {
          this.queryInfo[key] = this.initQuery[key]
        }
      })
      // 重置搜索条组件里的参数
      if (this.$refs.headerSelect) {
        this.$refs.headerSelect.clear()
      }
    },
    // 获取搜索条选中的条件名
    getSearchLabels () {
      return compact(Object.values(this.$refs.searchFormRef.formNames)).join('_')
    },
    // 删除
    del: throttle(function (apiMethod, id, rowName = '') {
      this.$confirm(`<p class="confirm-message">此操作将永久删除 <b class="danger">${rowName}</b>，是否继续?</p>`, '删除警告', {
        type: 'error',
        dangerouslyUseHTMLString: true
      })
        .then(async () => {
          this.loading = true
          try {
            await apiMethod(id)
            this.$message.success('删除成功!')
            if (this.tableData.length - 1 === 0 && this.queryInfo.pageNum > 1) this.queryInfo.pageNum--
            await this.renderTable()
          } catch (e) {
          } finally {
            this.loading = false
          }
        })
        .catch(() => {
          this.$message('已取消删除')
        })
    }, 1000, {})
  }
}
